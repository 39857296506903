<template>
  <div>
    <div class="ygdtitle">已通过订单</div>
    <div class="orderdetail">
      <div class="num">
        订单编号{{ num }}<span @click="back()">返回列表</span>
      </div>
      <steps :active="active"></steps>
      <trace :active="traceactive" :trace="trace"></trace>
      <articlecont :article="article"></articlecont>
    </div>
  </div>
</template>
<script>
import steps from '../components/steps'
import trace from '../components/trace'
import articlecont from '../components/articlecont'
import { ordernum, ordertrace, orderarti } from '../../../API/YGD/orderdetail.js'
export default {
  components: {
    steps,
    trace,
    articlecont
  },
  data () {
    return {
      wid: "",//当前作品ID
      active: 0, //订单活跃状态
      trace: [],
      article: {},
      num: "",
      traceactive: 1,
    }
  },
  mounted () {
    this.wid = this.$route.query.wid;
    this.ordersnum(this.wid);
    this.orderstrace(this.wid)
    this.ordersarti(this.wid)
  },
  methods: {
    //查看当前订单状态
    ordersnum (id) {
      ordernum(id).then(res => {
        this.num = res.data.data.contributionnumber
        if (res.data.data.state == 0 || res.data.data.state == 1 || res.data.data.state == 71 || res.data.data.state == 72 || res.data.data.state == 81 || res.data.data.state == 82) {
          this.active = 2
        } else if (res.data.data.state == 2 || res.data.data.state == 21 || res.data.data.state == 22) {
          this.active = 3
        } else if (res.data.data.state == 30 || res.data.data.state == 31 || res.data.data.state == 32 || res.data.data.state == 5 || res.data.data.state == 6) {
          this.active = 4
        } else {
          this.active = 5
        }

      })
    },
    //查看追踪
    orderstrace (id) {
      ordertrace(id).then(res => {
        this.trace = res.data.data
        this.traceactive = this.trace.length - 1
      })
    },
    //查看文章详情
    ordersarti (id) {
      orderarti(id).then(res => {

        this.article = res.data.data

      })
    },
    //返回列表
    back () {
      this.$router.push({ path: '/Staff/PassedOrder', query: { id: 2 } })
    }
  }
}
</script>

<style>
.ygdtitle {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
</style>